import { Grid, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import logo from "./img/logo.png";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function Footer() {
  return (
    <Box id="contacts" sx={{ backgroundColor: "#011D48", padding: 2 }}>
      <Container sx={{ color: "white" }}>
        <Grid container direction="row">
          <Grid md={4}>
            <img src={logo} />
            <Typography sx={{ mt: 2 }}>
              © 2014-2025 Все права защищены.
            </Typography>
          </Grid>
          <Grid md={4}>
            <Typography fontSize={18}>Центр лестниц "Эволюция"</Typography>
            <Typography fontSize={12}>г. Владимир, г. Нижний Новгород</Typography>
          </Grid>
          <Grid md={4} sx={{textAlign: 'right'}}>
            <Stack direction="row" spacing={3}>
                <LocalPhoneOutlinedIcon color="whiteq"/>
                &nbsp;+79255879444
            </Stack>
            <Stack direction="row" spacing={3}>
                <AlternateEmailOutlinedIcon color="whiteq"/>
                &nbsp;support@lestnica-evo.ru
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
